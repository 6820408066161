import dayjs from "dayjs"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import Footer from "../../components/Footer/footer"
import Header from "../../components/header"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StoreContext } from "../../store"
import tabs from "../../utilities/tabs"
import * as newsroomStyles from "./newsroom.module.css"

const Newsroom = ({ data, location }) => {
  const { viewPortWidth } = React.useContext(StoreContext)

  // Featured Newsroom Post (Can be News or Press)
  const featuredNewsroomPost =
    data.allWp.edges[0].node.overviewNewsroom.overviewNewsroom
      .featuredNewsroomPost[0]
  const featuredNewsroomPostLogo =
    featuredNewsroomPost.featuredImage.node.localFile.childImageSharp !== null
      ? featuredNewsroomPost.featuredImage.node.localFile.childImageSharp
          .gatsbyImageData
      : featuredNewsroomPost.featuredImage.node.localFile.publicURL
  const featuredNewsroomPostLogoFinal = getImage(featuredNewsroomPostLogo)
  const featuredNewsroomPostId = featuredNewsroomPost.databaseId

  // News Items
  let newsLogo = ""
  let newsLogoFinal = ""
  const newsItems = data.allWpNews.edges
    .filter(x => x.node.databaseId !== featuredNewsroomPostId)
    .sort((a, b) => {
      const dateA = new Date(a.node.dateGmt).getTime()
      const dateB = new Date(b.node.dateGmt).getTime()
      return dateA > dateB ? -1 : 1
    })

  // Press Release Items
  let pressItems = data.allWpPressRelease.edges.sort((a, b) => {
    const dateA = new Date(a.node.dateGmt).getTime()
    const dateB = new Date(b.node.dateGmt).getTime()
    return dateA > dateB ? -1 : 1
  })

  let newsHeaderBlock = ""
  let newsFooterBlock = ""
  let newsHeaderBlockMarkup
  let newsFooterBlockMarkup

  // Featured Header Item

  const newsBlockFieldName =
    data.allWp.edges[0].node.overviewNewsroom.overviewNewsroom.fieldGroupName

  if (
    data.allWp.edges[0].node.overviewNewsroom.overviewNewsroom
      .newsroomHeaderContent !== null
  ) {
    if (
      data.allWp.edges[0].node.overviewNewsroom.overviewNewsroom
        .newsroomHeaderContent.content
    ) {
      newsHeaderBlock =
        data.allWp.edges[0].node.overviewNewsroom.overviewNewsroom
          .newsroomHeaderContent.content
    }
    newsHeaderBlockMarkup = () => {
      if (newsHeaderBlock) {
        return { __html: `${newsHeaderBlock}` }
      }
    }
  }

  // Featured Footer Item
  if (
    data.allWp.edges[0].node.overviewNewsroom !== null &&
    data.allWp.edges[0].node.overviewNewsroom !== undefined
  ) {
    if (
      data.allWp.edges[0].node.overviewNewsroom.overviewNewsroom
        .newsroomFooterContent.content
    ) {
      newsFooterBlock =
        data.allWp.edges[0].node.overviewNewsroom.overviewNewsroom
          .newsroomFooterContent.content
    }
    newsFooterBlockMarkup = () => {
      if (newsFooterBlock) {
        return { __html: `${newsFooterBlock}` }
      }
    }
  }

  const allNewsYears = [
    ...new Set(newsItems.map(item => dayjs(item.node.date).year())),
  ]
  const initialYearsToDisplay = allNewsYears.slice(0, 2)

  const [displayedNewsYears, setDisplayedNewsYears] = useState([
    ...initialYearsToDisplay,
  ])

  // State for the lists
  const [newsList, setNewsList] = useState([
    ...newsItems.filter(item =>
      initialYearsToDisplay.includes(dayjs(item.node.date).year())
    ),
  ])
  const [pressList, setPressList] = useState([...pressItems.slice(0, 9)])

  // State to trigger load more
  const [loadMoreNews, setLoadMoreNews] = useState(false)
  const [loadMorePress, setLoadMorePress] = useState(false)

  // State of whether there is more to load
  const [hasMoreNews, setHasMoreNews] = useState(newsItems.length > 9)
  const [hasMorePress, setHasMorePress] = useState(pressItems.length > 9)

  // Load more button click
  const handleLoadMoreNews = () => {
    setLoadMoreNews(true)
  }
  const handleLoadMorePress = () => {
    setLoadMorePress(true)
  }

  // Hook up tab logic to tabs
  useEffect(() => {
    tabs()
  })

  // Handle loading more news
  useEffect(() => {
    if (loadMoreNews && hasMoreNews) {
      const displayedNewsItemsLength = newsList.length
      const isMore = displayedNewsItemsLength < newsItems.length

      const displayedYearsLength = displayedNewsYears.length
      const yearsToInclude = allNewsYears.slice(0, displayedYearsLength + 2)
      setDisplayedNewsYears([...yearsToInclude])

      const nextResults = isMore
        ? newsItems
            .slice(displayedNewsItemsLength)
            .filter(item =>
              yearsToInclude.includes(dayjs(item.node.date).year())
            )
        : []
      setNewsList([...newsList, ...nextResults])
      setLoadMoreNews(false)
    }
  }, [
    loadMoreNews,
    hasMoreNews,
    newsList,
    newsItems,
    displayedNewsYears,
    allNewsYears,
  ])

  //Check if there is more news
  useEffect(() => {
    const isMore = newsList.length < newsItems.length
    setHasMoreNews(isMore)
  }, [newsList, hasMoreNews, newsItems])

  // Handle loading more press releases
  useEffect(() => {
    if (loadMorePress && hasMorePress) {
      const currentLength = pressList.length
      const isMore = currentLength < pressItems.length
      const nextResults = isMore
        ? pressItems.slice(currentLength, currentLength + 9)
        : []
      setPressList([...pressList, ...nextResults])
      setLoadMorePress(false)
    }
  }, [loadMorePress, hasMorePress, pressList, pressItems])

  // Check if there are more press releases
  useEffect(() => {
    const isMore = pressList.length < pressItems.length
    setHasMorePress(isMore)
  }, [pressList, hasMorePress, pressItems])

  return (
    <Layout>
      <Seo pageData={data.wpPage} location={location} />

      <div className="archive post-type-archive post-type-archive-patterns-case-study wp-embed-responsive">
        <div className="Page">
          <Header siteTitle={`GoodHire`} viewPortWidth={viewPortWidth} />

          {data.allWp.edges[0].node.overviewNewsroom.overviewNewsroom
            .newsroomHeaderContent !== null && (
            <span
              className={`${newsBlockFieldName}-header`}
              dangerouslySetInnerHTML={newsHeaderBlockMarkup()}
            />
          )}

          <div className="Archive no-padding-top">
            <div className="Wrap">
              <div className="Archive is-News no-padding-top">
                {/* News/Press Tabs */}
                <div className="TabsBlock">
                  <div
                    className={`TabsBlock-labels ${newsroomStyles.NewsroomTabBlock}`}
                  >
                    <ul>
                      <li
                        id="News"
                        className={`TabsBlock-label ${newsroomStyles.NewsroomTabLabel}`}
                      >
                        <div className="TabsBlock-labelContent">
                          <div className="TabsBlock-labelTitle bold u-textColor--default">
                            News
                          </div>
                        </div>
                      </li>
                      <li
                        id="Press"
                        className={`TabsBlock-label ${newsroomStyles.NewsroomTabLabel}`}
                      >
                        <div className="TabsBlock-labelContent">
                          <div className="TabsBlock-labelTitle bold u-textColor--default">
                            Press
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="TabsBlock-tabs">
                    {/* News Archive */}
                    <div className="TabsBlock-tab">
                      <h2
                        className={`HeaderBlock-title h1 u-paddingBottom--xxSmall ${newsroomStyles.NewsPressTitle}`}
                      >
                        In the News
                      </h2>
                      {/* Featured News Post */}
                      <div className={newsroomStyles.FeaturedNewsPost}>
                        <div className="u-marginBottom--xSmall">
                          <article
                            id={`post-${featuredNewsroomPost.databaseId}`}
                            className={`post-${featuredNewsroomPost.databaseId} ${newsroomStyles.FeaturedNewsContent}`}
                          >
                            <div className={newsroomStyles.FeaturedNewsLogo}>
                              {featuredNewsroomPost.featuredImage.node.localFile
                                .childImageSharp === null &&
                                featuredNewsroomPost.featuredImage.node
                                  .localFile.extension === "svg" && (
                                  <img
                                    src={
                                      featuredNewsroomPost.featuredImage.node
                                        .localFile.publicURL
                                    }
                                    alt={
                                      featuredNewsroomPost.featuredImage.node
                                        .altText
                                    }
                                  />
                                )}

                              {featuredNewsroomPost.featuredImage.node.localFile
                                .childImageSharp !== null &&
                                featuredNewsroomPost.featuredImage.node
                                  .localFile.extension !== "svg" && (
                                  <GatsbyImage
                                    image={featuredNewsroomPostLogoFinal}
                                    alt={
                                      featuredNewsroomPost.featuredImage.node
                                        .altText
                                    }
                                    objectFit="scale-down"
                                    objectPosition="50% 50%"
                                  />
                                )}
                            </div>
                            <div className={newsroomStyles.FeaturedNewsInfo}>
                              <header>
                                <div
                                  className={`${newsroomStyles.ContentDate} ${newsroomStyles.FeaturedNewsDate}`}
                                >
                                  {featuredNewsroomPost.date}
                                </div>
                                <h3
                                  className={`${newsroomStyles.ContentTitle} ${newsroomStyles.FeaturedNewsTitle}`}
                                >
                                  {featuredNewsroomPost.title}
                                </h3>
                                <div
                                  className={newsroomStyles.FeaturedNewsExcerpt}
                                >
                                  {parse(featuredNewsroomPost.excerpt)}
                                </div>
                                <a
                                  href={
                                    featuredNewsroomPost.mediaCoverage
                                      .newsArticleUrl
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                  className="Button is-outline"
                                >
                                  Read More
                                </a>
                              </header>
                            </div>
                          </article>
                        </div>
                      </div>

                      {/* News Archive Grid */}
                      <div className="Archive-grid">
                        {newsList.map((item, i) => {
                          const newsTitle = item.node.title
                          const newsDate = item.node.date
                          const newsLogoAlt =
                            item.node.featuredImage.node.altText
                          const newsArticleUrl =
                            item.node.mediaCoverage.newsArticleUrl
                          const newsArticleId = item.node.databaseId
                          const newsArticlePublicURL =
                            item.node.featuredImage.node.localFile.publicURL
                          const showYearSeparator =
                            i == 0 ||
                            (!!newsList[i - 1] &&
                              dayjs(newsList[i - 1].node.date).year() !==
                                dayjs(newsList[i].node.date).year())
                          if (
                            item.node.featuredImage.node.localFile
                              .childImageSharp !== null
                          ) {
                            newsLogo =
                              item.node.featuredImage.node.localFile
                                .childImageSharp.gatsbyImageData
                            newsLogoFinal = getImage(newsLogo)
                          } else {
                            newsLogo = newsArticlePublicURL
                          }

                          return (
                            <>
                              {showYearSeparator && (
                                <div
                                  className={newsroomStyles.GridYearSeparator}
                                >
                                  {dayjs(newsDate).format("YYYY")}
                                </div>
                              )}
                              <div
                                key={`news-item-${newsArticleId}`}
                                className={newsroomStyles.GridItemSpacing}
                              >
                                <div>
                                  <article
                                    id={`post-${newsArticleId}`}
                                    className={`${newsroomStyles.NewsContent} post-${newsArticleId} patterns-news type-patterns-news status-publish hentry has-post-thumbnail`}
                                  >
                                    <a
                                      href={newsArticleUrl}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <div>
                                        <div className="Content-image">
                                          <div
                                            className={
                                              newsroomStyles.ContentLogo
                                            }
                                          >
                                            {item.node.featuredImage.node
                                              .localFile.childImageSharp ===
                                              null &&
                                              item.node.featuredImage.node
                                                .localFile.extension ===
                                                "svg" && (
                                                <img
                                                  src={newsArticlePublicURL}
                                                  alt={newsLogoAlt}
                                                />
                                              )}

                                            {item.node.featuredImage.node
                                              .localFile.childImageSharp !==
                                              null &&
                                              item.node.featuredImage.node
                                                .localFile.extension !==
                                                "svg" && (
                                                <GatsbyImage
                                                  image={newsLogoFinal}
                                                  alt={newsLogoAlt}
                                                  objectFit="contain"
                                                  objectPosition="0 50%"
                                                />
                                              )}
                                          </div>
                                        </div>
                                        <div className="Content-text">
                                          <header className="Content-header">
                                            <div
                                              className={
                                                newsroomStyles.ContentDate
                                              }
                                            >
                                              {newsDate}
                                            </div>
                                            <h3
                                              className={
                                                newsroomStyles.ContentTitle
                                              }
                                            >
                                              {newsTitle}
                                            </h3>
                                          </header>
                                        </div>
                                      </div>
                                    </a>
                                  </article>
                                </div>
                              </div>
                            </>
                          )
                        })}
                      </div>
                      {hasMoreNews ? (
                        <div className={newsroomStyles.ButtonWrapper}>
                          <button
                            onClick={handleLoadMoreNews}
                            className="Button is-default"
                          >
                            Show More
                          </button>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                    {/* Press Archive */}
                    <div className="TabsBlock-tab">
                      <h2
                        className={`HeaderBlock-title h1 u-paddingBottom--xxSmall ${newsroomStyles.NewsPressTitle}`}
                      >
                        Press Releases
                      </h2>
                      {/* News Archive Grid */}
                      <div className="Archive-grid">
                        {pressList.map(item => {
                          const pressTitle = item.node.title
                          const pressDate = item.node.date
                          const pressExcerpt = item.node.excerpt
                          const pressReleaseSlug = item.node.slug
                          const pressReleaseId = item.node.databaseId

                          return (
                            <div
                              key={`news-item-${pressReleaseId}`}
                              className={newsroomStyles.GridItemSpacing}
                            >
                              <div>
                                <article
                                  id={`post-${pressReleaseId}`}
                                  className={`${newsroomStyles.PressContent} post-${pressReleaseId} patterns-news type-patterns-news status-publish hentry has-post-thumbnail is-Press`}
                                >
                                  <Link
                                    to={`/press-releases/${pressReleaseSlug}`}
                                  >
                                    <div>
                                      <div className="Content-text">
                                        <header className="Content-header">
                                          <div
                                            className={
                                              newsroomStyles.ContentDate
                                            }
                                          >
                                            {pressDate}
                                          </div>
                                          <h3
                                            className={
                                              newsroomStyles.ContentTitle
                                            }
                                          >
                                            {pressTitle}
                                          </h3>
                                          <div className="u-textColor--default">
                                            {parse(pressExcerpt)}
                                          </div>
                                        </header>
                                      </div>
                                    </div>
                                  </Link>
                                </article>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      {hasMorePress ? (
                        <div className={newsroomStyles.ButtonWrapper}>
                          <button
                            onClick={handleLoadMorePress}
                            className="Button is-default"
                          >
                            Show More
                          </button>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {data.allWp.edges[0].node.overviewNewsroom.overviewNewsroom
            .newsroomFooterContent !== null && (
            <span
              className={`${newsBlockFieldName}-footer`}
              dangerouslySetInnerHTML={newsFooterBlockMarkup()}
            />
          )}

          <Footer />
        </div>
      </div>
    </Layout>
  )
}

export default Newsroom

export const query = graphql`
  {
    allWpNews {
      edges {
        node {
          slug
          title
          date(formatString: "LL")
          dateGmt
          databaseId
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                  )
                }
                extension
                publicURL
              }
              altText
            }
          }
          mediaCoverage {
            newsArticleUrl
          }
        }
      }
    }
    allWpPressRelease {
      edges {
        node {
          slug
          title
          date(formatString: "LL")
          dateGmt
          databaseId
          link
          excerpt
        }
      }
    }
    wpPage(databaseId: { eq: 2327 }) {
      seo {
        title
        metaDesc
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphSiteName
        opengraphType
        readingTime
      }
      richSnippetSchemaJson
    }
    allWp {
      edges {
        node {
          overviewNewsroom {
            overviewNewsroom {
              featuredNewsroomPost {
                ... on WpNews {
                  slug
                  title
                  excerpt
                  date(formatString: "LL")
                  databaseId
                  featuredImage {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            formats: AUTO
                            placeholder: BLURRED
                            layout: FULL_WIDTH
                          )
                        }
                        extension
                        publicURL
                      }
                      altText
                    }
                  }
                  mediaCoverage {
                    newsArticleUrl
                  }
                }
              }
              fieldGroupName
              newsroomFooterContent {
                ... on WpPage {
                  id
                  content
                }
              }
              newsroomHeaderContent {
                ... on WpPage {
                  id
                  content
                }
              }
            }
          }
        }
      }
    }
  }
`
