// extracted by mini-css-extract-plugin
export var NewsPressTitle = "newsroom-module--NewsPressTitle--1ai8o";
export var FeaturedNewsPost = "newsroom-module--FeaturedNewsPost--3RfNo";
export var FeaturedNewsDate = "newsroom-module--FeaturedNewsDate--2zZM9";
export var ContentDate = "newsroom-module--ContentDate--P3quX";
export var FeaturedNewsTitle = "newsroom-module--FeaturedNewsTitle--3iPmX";
export var ContentTitle = "newsroom-module--ContentTitle--2ciU1";
export var FeaturedNewsContent = "newsroom-module--FeaturedNewsContent--1R9yO";
export var FeaturedNewsLogo = "newsroom-module--FeaturedNewsLogo--37AVV";
export var FeaturedNewsInfo = "newsroom-module--FeaturedNewsInfo--U8Rv5";
export var FeaturedNewsExcerpt = "newsroom-module--FeaturedNewsExcerpt--3nE30";
export var GridItemSpacing = "newsroom-module--GridItemSpacing--3RvGV";
export var NewsContent = "newsroom-module--NewsContent--3rwih";
export var PressContent = "newsroom-module--PressContent--2hi3v";
export var Content = "newsroom-module--Content--a0cfx";
export var isPress = "newsroom-module--is-Press--2NfUL";
export var ContentLogo = "newsroom-module--ContentLogo--2E46D";
export var ButtonWrapper = "newsroom-module--ButtonWrapper--2KeeD";
export var GridYearSeparator = "newsroom-module--GridYearSeparator--R6ARt";
export var NewsroomTabBlock = "newsroom-module--NewsroomTabBlock--3mPRz";
export var NewsroomTabLabel = "newsroom-module--NewsroomTabLabel--8H0Ne";